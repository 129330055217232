import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { cloneDeep, constant, times } from 'lodash';
import { Subject } from 'rxjs';
import { InfluenceFactorStateMap } from '@entscheidungsnavi/decision-data';
import { DecisionDataExportService } from '../app/data/decision-data-export.service';
import { AlternativeAtPositionInfos, ROBUSTNESS_CHECK_HISTOGRAM_BIN_COUNT, StartMessage, UpdateMessage } from './robustness-check-messages';
import { createRobustnessWorker } from './web-worker-utils';

export interface AlternativeResult {
  positionDistribution: number[];
  minUtility: number;
  maxUtility: number;
  avgUtility: number;
  utilityHistogram: number[];
}

export interface RobustnessWorkerResult {
  // Total number of iterations and speed summed across all workers
  iterationCount: number;
  // Results for every alternative
  alternatives: AlternativeResult[];
  // The frequencies of every influence factor state for every position [alternativeIdx][position]
  alternativeAtPositionInfos: AlternativeAtPositionInfos;
}

export type RobustnessWorkerSettings = Omit<StartMessage, 'decisionData'> & { objectivesCount: number };

@Injectable()
export class RobustnessCheckService implements OnDestroy {
  private workerPool: Worker[] = [];

  private results: {
    iterationCount: number;
    // How often each alternative (first index) is in each position (second index)
    positionCounts: number[][];
    // Data per alternative & position (stateCounts, drawnWeights, drawnCs)
    alternativeAtPositionInfos: AlternativeAtPositionInfos;
    // Statistics for each alternative
    utilitySum: number[];
    minUtility: number[];
    maxUtility: number[];
    // A histogram over the utilities for each alternative ([#alternativeIndex][#binIndex])
    utilityHistograms: number[][];
  };
  private settings: RobustnessWorkerSettings;

  /**
   * True iff the browser does not support web workers.
   */
  readonly noWorker = !Worker;

  get workerCount() {
    // Limit us half the systems logical cores
    return Math.max(Math.round(navigator.hardwareConcurrency / 2), 1);
  }

  /**
   * Emits the latest results from the robustness workers. Emits outside the Angular Zone.
   */
  readonly onUpdate$ = new Subject<void>();

  constructor(
    private zone: NgZone,
    private exportService: DecisionDataExportService,
  ) {}

  ngOnDestroy() {
    this.destroyWorkers();
  }

  private destroyWorkers() {
    while (this.workerPool.length > 0) {
      const worker = this.workerPool.pop();

      worker.removeAllListeners();
      // Terminating the worker might lead to errors, e.g. network errors from cancelled importScripts calls.
      // This prevents these errors from bubbling up.
      worker.addEventListener('error', e => e.preventDefault());

      worker.terminate();
    }
  }

  /**
   * Start a new worker calculation using the given data.
   *
   * @param settings - The settings for the calculation
   * @returns true if the calculation could be started, false otherwise
   */
  startWorker(settings: RobustnessWorkerSettings) {
    this.destroyWorkers();

    const alternativeCount = settings.selectedAlternatives.length;
    this.settings = settings;
    this.results = {
      iterationCount: 0,
      positionCounts: times(alternativeCount, () => times(alternativeCount, constant(0))),
      alternativeAtPositionInfos: times(alternativeCount, () =>
        times(alternativeCount, () => ({
          drawnCs: settings.parameters.utilityFunctions ? times(settings.objectivesCount, () => null) : [],
          drawnWeights: settings.parameters.objectiveWeights ? times(settings.objectivesCount, () => null) : [],
          stateCounts: new InfluenceFactorStateMap<number[]>(),
        })),
      ),
      minUtility: times(alternativeCount, constant(Number.MAX_SAFE_INTEGER)),
      maxUtility: times(alternativeCount, constant(Number.MIN_SAFE_INTEGER)),
      utilitySum: times(alternativeCount, constant(0)),
      utilityHistograms: times(alternativeCount, () => times(ROBUSTNESS_CHECK_HISTOGRAM_BIN_COUNT, constant(0))),
    };

    this.resumeWorker();
  }

  pauseWorker() {
    this.destroyWorkers();
  }

  resumeWorker() {
    for (let c = 0; c < this.workerCount; c++) {
      const newWorker = createRobustnessWorker();

      this.zone.runOutsideAngular(() => {
        newWorker.addEventListener('message', e => this.onWorkerMessage(e));
        newWorker.addEventListener('error', e => console.error(e));
      });

      newWorker.postMessage({
        ...this.settings,
        decisionData: this.exportService.dataToText(null),
      } satisfies StartMessage);

      this.workerPool.push(newWorker);
    }
  }

  /**
   * This function runs outside the angular zone.
   */
  private onWorkerMessage(e: MessageEvent<UpdateMessage>) {
    const alternativeCount = this.settings.selectedAlternatives.length;

    // Aggregate the workers results into our current intermediate result
    for (let alternativeIndex = 0; alternativeIndex < alternativeCount; alternativeIndex++) {
      for (let position = 0; position < alternativeCount; position++) {
        this.results.positionCounts[alternativeIndex][position] += e.data.result.positions[alternativeIndex][position];

        const currAlternativeAtPositionInfo = this.results.alternativeAtPositionInfos[alternativeIndex][position];
        const newAlternativeAtPositionInfo = e.data.result.alternativeAtPositionInfos[alternativeIndex][position];

        // Update influence factor state distribution
        const stateCounts = InfluenceFactorStateMap.fromInnerMap(newAlternativeAtPositionInfo.stateCounts);
        for (const [key, counts] of stateCounts.entries()) {
          let frequencies = currAlternativeAtPositionInfo.stateCounts.get(key);

          if (frequencies == null) {
            frequencies = new Array(counts.length).fill(0);
            currAlternativeAtPositionInfo.stateCounts.set(key, frequencies);
          }

          counts.forEach((count, stateIndex) => {
            frequencies[stateIndex] += count;
          });
        }

        // draw weights per alternative and position
        for (let objectiveIdx = 0; objectiveIdx < newAlternativeAtPositionInfo.drawnWeights.length; objectiveIdx++) {
          if (newAlternativeAtPositionInfo.drawnWeights[objectiveIdx] == null) continue;
          currAlternativeAtPositionInfo.drawnWeights[objectiveIdx] = {
            min: Math.min(
              currAlternativeAtPositionInfo.drawnWeights[objectiveIdx]?.min ?? Number.MAX_SAFE_INTEGER,
              newAlternativeAtPositionInfo.drawnWeights[objectiveIdx].min,
            ),
            max: Math.max(
              currAlternativeAtPositionInfo.drawnWeights[objectiveIdx]?.max ?? Number.MIN_SAFE_INTEGER,
              newAlternativeAtPositionInfo.drawnWeights[objectiveIdx].max,
            ),
          };
        }

        // C (utility function parameter) per alternative and position
        for (let objectiveIdx = 0; objectiveIdx < newAlternativeAtPositionInfo.drawnCs.length; objectiveIdx++) {
          if (newAlternativeAtPositionInfo.drawnCs[objectiveIdx] == null) continue;

          if (newAlternativeAtPositionInfo.drawnCs[objectiveIdx] != null) {
            // non-verbal objectives
            currAlternativeAtPositionInfo.drawnCs[objectiveIdx] = {
              min: Math.min(
                currAlternativeAtPositionInfo.drawnCs[objectiveIdx]?.min ?? Number.MAX_SAFE_INTEGER,
                newAlternativeAtPositionInfo.drawnCs[objectiveIdx].min,
              ),
              max: Math.max(
                currAlternativeAtPositionInfo.drawnCs[objectiveIdx]?.max ?? Number.MIN_SAFE_INTEGER,
                newAlternativeAtPositionInfo.drawnCs[objectiveIdx].max,
              ),
            };
          }
        }
      }

      this.results.minUtility[alternativeIndex] = Math.min(this.results.minUtility[alternativeIndex], e.data.result.min[alternativeIndex]);
      this.results.maxUtility[alternativeIndex] = Math.max(this.results.maxUtility[alternativeIndex], e.data.result.max[alternativeIndex]);
      this.results.utilitySum[alternativeIndex] += e.data.result.sum[alternativeIndex];

      for (let binIndex = 0; binIndex < ROBUSTNESS_CHECK_HISTOGRAM_BIN_COUNT; binIndex++) {
        this.results.utilityHistograms[alternativeIndex][binIndex] += e.data.result.histograms[alternativeIndex][binIndex];
      }
    }

    this.results.iterationCount += e.data.iterationCount;

    this.onUpdate$.next();
  }

  getCurrentResult(): RobustnessWorkerResult {
    const alternativeCount = this.settings.selectedAlternatives.length;

    // Aggregate worker results
    const result = cloneDeep(this.results);

    // Normalize state distribution by position counts, and normalize positions
    for (let alternativeIndex = 0; alternativeIndex < alternativeCount; alternativeIndex++) {
      for (let position = 0; position < alternativeCount; position++) {
        if (result.positionCounts[alternativeIndex][position] === 0) continue;

        for (const [, value] of result.alternativeAtPositionInfos[alternativeIndex][position].stateCounts.entries()) {
          for (let stateIndex = 0; stateIndex < value.length; stateIndex++) {
            value[stateIndex] /= result.positionCounts[alternativeIndex][position];
          }
        }

        result.positionCounts[alternativeIndex][position] /= result.iterationCount;
      }
    }

    // Callback method
    return {
      alternatives: result.positionCounts.map((distribution, index) => ({
        positionDistribution: distribution,
        minUtility: result.minUtility[index],
        maxUtility: result.maxUtility[index],
        avgUtility: result.utilitySum[index] / result.iterationCount,
        utilityHistogram: result.utilityHistograms[index],
      })),
      alternativeAtPositionInfos: result.alternativeAtPositionInfos,
      iterationCount: result.iterationCount,
    };
  }

  getCurrentIterationCount() {
    return this.results.iterationCount;
  }
}
